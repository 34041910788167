@use "sass:math";
@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

div.container {
	.link.link {
		border-bottom: unset;
		color: $natural_black;
		line-height: ($marginGeneric * 3) - 1px;
		position: relative;

		span::after {
			background: $natural_black;
			left: 0;
			position: absolute;
		}
	}
}
